






import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '../../../store';

@Component({})
export default class AuthAlert extends Vue {
  userModule = UserModule;
}
